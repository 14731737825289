/* eslint-disable camelcase */
import React, { useState, useReducer, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, ListItemIcon, Collapse, Typography } from '@material-ui/core';
import { ArrowDropDown as DownArrowIcon, ArrowRight as RightArrowIcon } from '@material-ui/icons';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { COINCIDENT_EVENTS_DASH_TABLE } from 'gql/coincident_event';
import { DETECTED_EVENTS } from 'gql/detected_event';
import { LINKTOKEN_EVENTS } from 'gql/linktoken_event';
import colors from 'util/colors';
import handleGraphQLError from 'util/error';
import getValueAbbreviation from 'util/getValueAbbreviation';
import getSimpleSocialType from 'util/getSimpleSocialType';
import { renderUTCLongDate, getDatesForAnalytics } from 'util/date';
import openDashboardDrawer from 'util/drawerUtil';
import DashboardIntelligenceDrawerContext from 'contexts/DashboardIntelligenceDrawerContext';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import {
  DETECTED_EVENT_TYPE,
  THIRD_PARTY_MEDIA_EVENT_TYPES,
  APP_EVENT_TYPES,
  SALESFORCE_EVENT_TYPES,
  STARBUCKS_EVENT_TYPES,
  NARRATIVE_EVENT_TYPES,
  getClickableEventTypes,
  getTypeOrderMap
} from 'util/detectedEvents';
import formatValueWithSymbol from 'util/formatValueWithSymbol';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import { MENTIONS_AND_RATINGS } from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaConsts';
import DetectedEventTableHeader from './DetectedEventTableHeader';
import DetectedEventTableRow from './DetectedEventTableRow';
import AnnotationsList from './AnnotationsList';

const styles = makeStyles({
  collapseColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  collapseIconOverride: {
    cursor: 'pointer',
    minWidth: 'unset',
    color: colors.navy
  },
  verticalLine: {
    height: '100%',
    width: '1px',
    backgroundColor: colors.darkGray10
  },
  contentColumn: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column'
  },
  headerContainer: {
    cursor: 'pointer',
    zIndex: '1'
  },
  contentHeader: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#0A1734',
    marginRight: '10px'
  },
  horizontalLine: {
    height: '1px',
    flex: '1',
    backgroundColor: colors.darkGray10
  },
  containerOverride: {
    marginLeft: '-11px'
  },
  wrapperInnerOverride: {
    position: 'relative',

    '& > :not(:last-child):after': {
      content: '""',
      position: 'absolute',
      right: '0',
      bottom: '0',
      height: '1px',
      width: '92%',
      background: `${colors.darkGray10}`
    }
  }
});

export const getDetectedEventProps = detectedEvent => {
  const newProps = {};

  newProps.id = detectedEvent.id;

  let socialTypeKey = detectedEvent.channel;

  if (THIRD_PARTY_MEDIA_EVENT_TYPES.includes(detectedEvent.event_type)) {
    socialTypeKey = detectedEvent.event_type;
  }

  if (APP_EVENT_TYPES.includes(detectedEvent.event_type)) {
    socialTypeKey = detectedEvent.event_type;
  }

  if (SALESFORCE_EVENT_TYPES.includes(detectedEvent.event_type)) {
    socialTypeKey = detectedEvent.event_type;
  }

  if (STARBUCKS_EVENT_TYPES.includes(detectedEvent.event_type)) {
    socialTypeKey = detectedEvent.event_type;
  }

  if (NARRATIVE_EVENT_TYPES.includes(detectedEvent.event_type)) {
    socialTypeKey = detectedEvent.event_type;
  }

  newProps.source = getSimpleSocialType(socialTypeKey);

  // This event object is passed to the openDashboardDrawer function
  newProps.event = {
    type: detectedEvent.event_type,
    startDate: detectedEvent.event_start_date,
    endDate: detectedEvent.event_end_date,
    ...((detectedEvent.event_type === DETECTED_EVENT_TYPE.IM_POST_RESURGE ||
      detectedEvent.event_type === DETECTED_EVENT_TYPE.EG_POST_RESURGE) && {
      postDayLength: moment.utc().diff(detectedEvent.campaignEvent.completed_at, 'days')
    }),
    ...(detectedEvent?.campaignEvent && {
      campaignEvent: detectedEvent.campaignEvent
    }),
    // These 2 event types use the old drawers
    // DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE uses SourceSpikeDrawerView
    // DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL uses ConversionOutlierDrawerView
    ...((detectedEvent.event_type === DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE ||
      detectedEvent.event_type === DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL) && {
      detectedEventId: detectedEvent.id
    }),
    ...(detectedEvent?.linktoken && {
      linktoken: detectedEvent.linktoken
    }),
    originalObject: detectedEvent,
    // Third party media drawer requires additional props:
    ...(THIRD_PARTY_MEDIA_EVENT_TYPES.includes(detectedEvent.event_type) && {
      drawerType: MENTIONS_AND_RATINGS
    })
  };

  // mag_base is used as the median
  if (detectedEvent.mag_base > -1) {
    newProps.currentMedian = formatValueWithSymbol(detectedEvent.mag_base, {
      usePrefixSymbol: detectedEvent.mag_unit === 'revenue'
    });
  } else {
    newProps.currentMedian = '-';
  }

  // magnitude is used as the current detected amount
  newProps.metric = formatValueWithSymbol(detectedEvent.magnitude, {
    usePrefixSymbol: detectedEvent.mag_unit === 'revenue'
  });
  newProps.unit = detectedEvent.mag_unit;

  // mag_base can be -1, 0 or a positive number. (-1 means 'not enough data so don't use it in the calculation.)
  if (detectedEvent.mag_base > -1) {
    let difference =
      ((detectedEvent.magnitude - detectedEvent.mag_base) / detectedEvent.mag_base) * 100;
    difference = Math.round((difference + Number.EPSILON) * 100) / 100;

    if (difference > 0 || difference === 'Infinity') {
      newProps.aboveMedian = difference;
    }

    if (difference < 0) {
      newProps.belowMedian = difference;
    }

    newProps.valueChange = detectedEvent.magnitude - detectedEvent.mag_base;
    newProps.valueChange = formatValueWithSymbol(newProps.valueChange, {
      usePrefixSymbol: detectedEvent.mag_unit === 'revenue'
    });
  } else {
    // -1 means not enough data to compute. Don't show % change.
    // gets converted to '-' on display
    newProps.valueChange = null;
  }

  newProps.originalObject = detectedEvent;

  if (getClickableEventTypes().includes(detectedEvent.event_type)) {
    newProps.onClick = true;
  }

  return newProps;
};

const EVENT_METRIC = {
  impressions: 'IMPRESSIONS',
  'ga:users': 'USERS',
  totalUsers: 'USERS',
  'mcf:totalConversions': 'CONVERSIONS'
};

export const getLinktokenEventProps = linktokenEvent => {
  const newProps = {};

  newProps.id = linktokenEvent.id;
  newProps.source = getSimpleSocialType(linktokenEvent.linktoken.type, true);
  newProps.event = {
    id: linktokenEvent.id,
    postDayLength: linktokenEvent?.post_date
      ? moment.utc().diff(linktokenEvent.post_date, 'days')
      : null,
    numberOfPosts: linktokenEvent?.peak_num_posts,
    source: linktokenEvent?.source,
    sourceName: linktokenEvent.linktoken.remote_name,
    ...(linktokenEvent.goal && {
      gaGoalName: {
        goal_id: linktokenEvent.goal.goal_id,
        name: linktokenEvent.goal.name
      }
    }),
    startDate: linktokenEvent.begin_date,
    endDate: linktokenEvent.end_date,
    eventRelativeRank: linktokenEvent?.event_relative_rank,
    type: linktokenEvent.event_type,
    ...(linktokenEvent?.campaign_events?.[0] && {
      campaignEvent: linktokenEvent?.campaign_events?.[0]
    }),
    linktokenId: linktokenEvent.linktoken_id,
    linktoken: linktokenEvent.linktoken
  };

  newProps.currentMedian = '-';
  newProps.metric =
    linktokenEvent.event_metric === 'impressions'
      ? getValueAbbreviation(linktokenEvent.event_delta_count)
      : getValueAbbreviation(linktokenEvent.event_end_count);
  newProps.unit = EVENT_METRIC[linktokenEvent.event_metric];

  // gets converted to '-' on display
  newProps.valueChange = null;
  newProps.originalObject = linktokenEvent;

  newProps.onClick = {};

  return newProps;
};

const DetectedEventsTable = props => {
  const { startDate, endDate, typeFilter } = props;
  const [numberOfEvents, setNumberOfEvents] = useState(0);
  const [isDateCollapsed, setIsDateCollapsed] = useState(false);
  const [isAnnotationsCollapsed, setIsAnnotationsCollapsed] = useState(false);
  const [isCorrelationsCollapsed, setIsCorrelationsCollapsed] = useState(true);
  const [isNewEventsCollapsed, setIsNewEventsCollapsed] = useState(false);
  const [isContinuedEventsCollapsed, setIsContinuedEventsCollapsed] = useState(true);
  const [correlations, setCorrelations] = useState([]);
  const [newEvents, setNewEvents] = useState([]);
  const [continuedEvents, setContinuedEvents] = useState([]);
  const drawerContext = useContext(DashboardIntelligenceDrawerContext);
  const socialPostDrawerContext = useContext(PostDrawerViewContext);
  const classes = styles();
  const { start, end } = getDatesForAnalytics(startDate, endDate);

  const LOAD_NEXT = 'load_next';
  const ACTIVE_AFTER = 'active_after';
  const RESET_AFTER = 'reset_after';
  const PAGINATION_COUNT = 25;

  const afterReducer = (state, action) => {
    switch (action.type) {
      case LOAD_NEXT:
        return { ...state, after: state.after + PAGINATION_COUNT };
      case ACTIVE_AFTER:
        return { ...state, ...action };
      case RESET_AFTER:
        return { ...state, after: 0 };
      default:
        return state;
    }
  };

  const [afterRow, afterRowDispatch] = useReducer(afterReducer, {
    after: 0,
    // determines if the pagination can still be triggered per resolver
    detectedActive: false,
    linktokenActive: false
  });
  const [lastEventRowId, setLastEventRowId] = useState(null);
  const [lazyLoading, setLazyLoading] = useState(false);

  const [
    getCoincidentEvents,
    { loading: coincidentLoading, error: coincidentError, data: coincidentData }
  ] = useLazyQuery(COINCIDENT_EVENTS_DASH_TABLE, {
    fetchPolicy: 'network-only'
  });

  const typeSort = (typeA, typeB) => {
    if (getTypeOrderMap()[typeA[0]] > getTypeOrderMap()[typeB[0]]) {
      return 1;
    }

    if (getTypeOrderMap()[typeA[0]] < getTypeOrderMap()[typeB[0]]) {
      return -1;
    }

    return 0;
  };

  const changeSort = (eventA, eventB) => {
    const differenceA = eventA.mag_base > -1 ? eventA.magnitude - eventA.mag_base : null;
    const differenceB = eventB.mag_base > -1 ? eventB.magnitude - eventB.mag_base : null;

    if (differenceA == null && differenceB == null) {
      return 0;
    }

    if (differenceA == null && differenceB != null) {
      return 1;
    }

    if (differenceA != null && differenceB == null) {
      return -1;
    }

    if (differenceA > differenceB) {
      return -1;
    }

    if (differenceA < differenceB) {
      return 1;
    }

    return 0;
  };

  const handleFormatDetectedEvents = detectedEvents => {
    const tempDetectedEvents = [];
    const tempNewEvents = [];
    const tempContinuedEvents = [];

    const groupedEvents = detectedEvents.reduce((acc, currentValue) => {
      const eventType = currentValue.event_type;

      if (acc[eventType]) {
        acc[eventType].push(currentValue);
      } else {
        acc[eventType] = [currentValue];
      }

      return acc;
    }, {});

    Object.entries(groupedEvents)
      .sort(typeSort)
      .forEach(([, values]) => {
        const sortedValues = values.sort(changeSort);

        sortedValues.forEach(sortedEvent => {
          tempDetectedEvents.push(sortedEvent);
        });
      });

    tempDetectedEvents.forEach(detectedEvent => {
      const eventStartDate = moment.utc(detectedEvent.event_start_date);
      const detectedEventRow = {
        ...getDetectedEventProps(detectedEvent),
        id: `${detectedEvent.id}_DE`
      };

      if (eventStartDate.isSameOrAfter(start) && eventStartDate.isSameOrBefore(end)) {
        tempNewEvents.push(detectedEventRow);
      }

      if (eventStartDate.isBefore(start)) {
        tempContinuedEvents.push(detectedEventRow);
      }
    });

    // set to active, will need to lazy load more assets through fetchMore.
    afterRowDispatch({
      type: ACTIVE_AFTER,
      detectedActive: detectedEvents.length >= PAGINATION_COUNT
    });

    return {
      tempNewEvents,
      tempContinuedEvents
    };
  };

  const handleFormatLinktokenEvents = linktokenEvents => {
    const tempNewEvents = [];
    const tempContinuedEvents = [];

    linktokenEvents.forEach(linktokenEvent => {
      const eventStartDate = moment.utc(linktokenEvent.begin_date);
      const linktokenEventRow = {
        ...getLinktokenEventProps(linktokenEvent),
        id: `${linktokenEvent.id}_LE`
      };

      if (eventStartDate.isSameOrAfter(start) && eventStartDate.isSameOrBefore(end)) {
        tempNewEvents.push(linktokenEventRow);
      }

      if (eventStartDate.isBefore(start)) {
        tempContinuedEvents.push(linktokenEventRow);
      }
    });

    // set to active, will need to lazy load more assets through fetchMore.
    afterRowDispatch({
      type: ACTIVE_AFTER,
      linktokenActive: linktokenEvents.length >= PAGINATION_COUNT
    });

    return {
      tempNewEvents,
      tempContinuedEvents
    };
  };

  const [
    getDetectedEvents,
    {
      loading: detectedLoading,
      error: detectedError,
      data: detectedData,
      fetchMore: detectedFetchMore
    }
  ] = useLazyQuery(DETECTED_EVENTS, {
    fetchPolicy: 'network-only'
  });

  const [
    getLinktokenEvents,
    {
      loading: linktokenLoading,
      error: linktokenError,
      data: linktokenData,
      fetchMore: linktokenFetchMore
    }
  ] = useLazyQuery(LINKTOKEN_EVENTS, {
    fetchPolicy: 'network-only'
  });

  const scrollObserver = (entries, observer) => {
    entries.forEach(en => {
      if (en.intersectionRatio > 0) {
        afterRowDispatch({ type: LOAD_NEXT });
        observer.unobserve(en.target);
      }
    });
  };

  const bottomBoundaryRef = useCallback(
    node => {
      if (node !== null && (afterRow.detectedActive || afterRow.linktokenActive)) {
        const observer = new IntersectionObserver(scrollObserver);
        observer.observe(node);
      }
    },
    [afterRow.detectedActive, afterRow.linktokenActive]
  );

  useEffect(() => {
    if (afterRow.detectedActive && afterRow.after > 0) {
      setLazyLoading(true);

      detectedFetchMore({
        variables: { after: afterRow.after },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const { tempNewEvents, tempContinuedEvents } = handleFormatDetectedEvents(
            fetchMoreResult.detectedEvents
          );

          setNewEvents(prevState => [...prevState, ...tempNewEvents]);
          setContinuedEvents(prevState => [...prevState, ...tempContinuedEvents]);
        }
      }).then(() => {
        setLazyLoading(false);
      });
    }

    if (afterRow.linktokenActive && afterRow.after > 0) {
      setLazyLoading(true);

      linktokenFetchMore({
        variables: { after: afterRow.after },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const { tempNewEvents, tempContinuedEvents } = handleFormatLinktokenEvents(
            fetchMoreResult.linktokenEvents
          );

          setNewEvents(prevState => [...prevState, ...tempNewEvents]);
          setContinuedEvents(prevState => [...prevState, ...tempContinuedEvents]);
        }
      }).then(() => {
        setLazyLoading(false);
      });
    }
  }, [afterRow.after]);

  useEffect(() => {
    if (startDate) {
      const startDateGraph = moment
        .utc(start)
        .clone()
        .subtract(3, 'd');
      const endDateGraph = moment
        .utc(end)
        .clone()
        .add(3, 'd');

      // typeFilter is a mix of: ['correlations', 'media', 'social', 'web', 'conversion]

      // Run all the queries.
      if (!typeFilter.length || (typeFilter.length > 1 && typeFilter.includes('correlations'))) {
        getCoincidentEvents({
          variables: {
            startDate: start,
            endDate: end,
            graph: false
          }
        });

        // graphs for detectedEvents and linktokenEvents are +/- 3 days
        getDetectedEvents({
          variables: {
            startDate: start,
            endDate: end,
            startDateGraph,
            endDateGraph,
            typeFilter,
            count: PAGINATION_COUNT
          }
        });

        getLinktokenEvents({
          variables: {
            startDate: start,
            endDate: end,
            startDateGraph,
            endDateGraph,
            typeFilter,
            count: PAGINATION_COUNT
          }
        });
      }

      // run only coincident query. set all others to null.
      if (typeFilter.length === 1 && typeFilter.includes('correlations')) {
        getCoincidentEvents({
          variables: {
            startDate: start,
            endDate: end,
            graph: false
          }
        });

        setNewEvents([]);
        setContinuedEvents([]);
      }

      // run the detected events and linktoken events queries only. Set correlations to null.
      if (typeFilter.length && !typeFilter.includes('correlations')) {
        getDetectedEvents({
          variables: {
            startDate: start,
            endDate: end,
            startDateGraph,
            endDateGraph,
            typeFilter,
            count: PAGINATION_COUNT
          }
        });

        getLinktokenEvents({
          variables: {
            startDate: start,
            endDate: end,
            startDateGraph,
            endDateGraph,
            typeFilter,
            count: PAGINATION_COUNT
          }
        });

        setCorrelations([]);
      }
    }
  }, [startDate, typeFilter]);

  useEffect(() => {
    afterRowDispatch({ type: RESET_AFTER });
  }, [startDate]);

  useEffect(() => {
    if (coincidentError || detectedError || linktokenError) {
      handleGraphQLError(coincidentError || detectedError || linktokenError);
    }
  }, [coincidentError, detectedError, linktokenError]);

  useEffect(() => {
    const tempCorrelations = [];

    if (coincidentData?.coincidentEvents?.length > 0) {
      tempCorrelations.push(...coincidentData.coincidentEvents);
    }

    setCorrelations(tempCorrelations);
  }, [coincidentData]);

  useEffect(() => {
    const tempNewEventsArr = [];
    const tempContinuedEventsArr = [];

    if (detectedData?.detectedEvents?.length > 0) {
      const { tempNewEvents, tempContinuedEvents } = handleFormatDetectedEvents(
        detectedData.detectedEvents
      );

      tempNewEventsArr.push(...tempNewEvents);
      tempContinuedEventsArr.push(...tempContinuedEvents);
    }

    if (linktokenData?.linktokenEvents?.length > 0) {
      const { tempNewEvents, tempContinuedEvents } = handleFormatLinktokenEvents(
        linktokenData.linktokenEvents
      );

      tempNewEventsArr.push(...tempNewEvents);
      tempContinuedEventsArr.push(...tempContinuedEvents);
    }

    setNewEvents(tempNewEventsArr);
    setContinuedEvents(tempContinuedEventsArr);
  }, [detectedData, linktokenData]);

  useEffect(() => {
    let tempNumberOfEvents = 0;

    if (correlations?.length > 0) {
      tempNumberOfEvents += correlations.length;
    }

    if (newEvents?.length > 0) {
      tempNumberOfEvents += newEvents.length;
    }

    if (continuedEvents?.length > 0) {
      tempNumberOfEvents += continuedEvents.length;
    }

    if ([...newEvents, ...continuedEvents].slice(-1)?.length) {
      const [lastEvent] = [...newEvents, ...continuedEvents].slice(-1);

      setLastEventRowId(lastEvent.id);
    }

    setNumberOfEvents(tempNumberOfEvents);
  }, [correlations, newEvents, continuedEvents]);

  return (
    <>
      <DetectedEventTableHeader useSpacer useHeaderShadow />
      <Box pl={15} pb={13}>
        <Grid container wrap="nowrap">
          <Grid
            item
            className={classes.collapseColumn}
            onClick={() => setIsDateCollapsed(prevState => !prevState)}
          >
            <ListItemIcon classes={{ root: classes.collapseIconOverride }}>
              {!isDateCollapsed ? <DownArrowIcon /> : <RightArrowIcon />}
            </ListItemIcon>
            {!isDateCollapsed && <Box className={classes.verticalLine} />}
          </Grid>
          <Grid item className={classes.contentColumn}>
            <Grid container alignItems="center" className={classes.headerContainer}>
              <Typography variantMapping={{ body1: 'span' }} className={classes.contentHeader}>
                {renderUTCLongDate(startDate)}{' '}
                {numberOfEvents > 0
                  ? `- ${numberOfEvents} Event${numberOfEvents > 1 ? 's' : ''}`
                  : ''}
              </Typography>
              <Box className={classes.horizontalLine} />
            </Grid>
            <Collapse in={!isDateCollapsed}>
              <Grid container wrap="nowrap">
                <Grid item className={classes.collapseColumn}>
                  <ListItemIcon
                    classes={{ root: classes.collapseIconOverride }}
                    onClick={() => setIsAnnotationsCollapsed(prevState => !prevState)}
                  >
                    {!isAnnotationsCollapsed ? <DownArrowIcon /> : <RightArrowIcon />}
                  </ListItemIcon>
                  {!isAnnotationsCollapsed && <Box className={classes.verticalLine} />}
                </Grid>
                <Grid item className={classes.contentColumn}>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.headerContainer}
                    onClick={() => setIsAnnotationsCollapsed(prevState => !prevState)}
                  >
                    <Typography
                      variantMapping={{ body1: 'span' }}
                      className={classes.contentHeader}
                    >
                      Annotations
                    </Typography>
                    <Box className={classes.horizontalLine} />
                  </Grid>
                  <Collapse
                    in={!isAnnotationsCollapsed}
                    classes={{
                      root: classes.containerOverride,
                      wrapperInner: classes.wrapperInnerOverride
                    }}
                  >
                    <AnnotationsList
                      startDate={start}
                      endDate={end}
                      setIsAnnotationsCollapsed={setIsAnnotationsCollapsed}
                      dashboard
                    />
                  </Collapse>
                </Grid>
              </Grid>
              <Grid container wrap="nowrap">
                <Grid item className={classes.collapseColumn}>
                  <ListItemIcon
                    classes={{ root: classes.collapseIconOverride }}
                    onClick={() => setIsCorrelationsCollapsed(prevState => !prevState)}
                  >
                    {!isCorrelationsCollapsed ? <DownArrowIcon /> : <RightArrowIcon />}
                  </ListItemIcon>
                  {!isCorrelationsCollapsed && <Box className={classes.verticalLine} />}
                </Grid>
                <Grid item className={classes.contentColumn}>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.headerContainer}
                    onClick={() => setIsCorrelationsCollapsed(prevState => !prevState)}
                  >
                    <Typography
                      variantMapping={{ body1: 'span' }}
                      className={classes.contentHeader}
                    >
                      Correlations
                    </Typography>
                    <Box className={classes.horizontalLine} />
                  </Grid>
                  <Collapse
                    in={!isCorrelationsCollapsed}
                    classes={{
                      root: classes.containerOverride,
                      wrapperInner: classes.wrapperInnerOverride
                    }}
                  >
                    {correlations?.length === 0 && (
                      <Grid container justifyContent="center">
                        <Box p={16}>
                          {coincidentLoading ? (
                            <AlbLoading />
                          ) : (
                            <Typography>There are no correlations</Typography>
                          )}
                        </Box>
                      </Grid>
                    )}
                    {correlations?.map(correlation => {
                      const event = {
                        type: 'CORRELATION',
                        ...correlation
                      };

                      return (
                        <DetectedEventTableRow
                          key={correlation.id}
                          source="correlation"
                          event={event}
                          onClick={() => {
                            openDashboardDrawer(
                              { date: moment.utc(start), ...event },
                              drawerContext
                            );
                          }}
                        />
                      );
                    })}
                  </Collapse>
                </Grid>
              </Grid>
              <Grid container wrap="nowrap">
                <Grid item className={classes.collapseColumn}>
                  <ListItemIcon
                    classes={{ root: classes.collapseIconOverride }}
                    onClick={() => setIsNewEventsCollapsed(prevState => !prevState)}
                  >
                    {!isNewEventsCollapsed ? <DownArrowIcon /> : <RightArrowIcon />}
                  </ListItemIcon>
                  {!isNewEventsCollapsed && <Box className={classes.verticalLine} />}
                </Grid>
                <Grid item className={classes.contentColumn}>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.headerContainer}
                    onClick={() => setIsNewEventsCollapsed(prevState => !prevState)}
                  >
                    <Typography
                      variantMapping={{ body1: 'span' }}
                      className={classes.contentHeader}
                    >
                      New Events
                    </Typography>
                    <Box className={classes.horizontalLine} />
                  </Grid>
                  <Collapse
                    in={!isNewEventsCollapsed}
                    classes={{
                      root: classes.containerOverride,
                      wrapperInner: classes.wrapperInnerOverride
                    }}
                  >
                    {newEvents?.length === 0 && (
                      <Grid container justifyContent="center">
                        <Box p={16}>
                          {detectedLoading || linktokenLoading ? (
                            <AlbLoading />
                          ) : (
                            <Typography>There are no new events</Typography>
                          )}
                        </Box>
                      </Grid>
                    )}
                    {newEvents?.map(newEvent => (
                      <DetectedEventTableRow
                        key={newEvent.id}
                        dateRange={{
                          range: {
                            startDate: moment
                              .utc(start)
                              .clone()
                              .subtract(3, 'd'),
                            endDate: moment
                              .utc(end)
                              .clone()
                              .add(3, 'd')
                              .endOf('day')
                          }
                        }}
                        selectedDate={moment.utc(start)}
                        {...newEvent}
                        onClick={
                          newEvent.onClick
                            ? () =>
                                openDashboardDrawer(
                                  newEvent.event,
                                  drawerContext,
                                  socialPostDrawerContext
                                )
                            : null
                        }
                        graph
                        ref={newEvent.id === lastEventRowId ? bottomBoundaryRef : null}
                      />
                    ))}
                    {lazyLoading && (
                      <Grid container justifyContent="center">
                        <Box p={16}>
                          <AlbLoading />
                        </Box>
                      </Grid>
                    )}
                  </Collapse>
                </Grid>
              </Grid>
              <Grid container wrap="nowrap">
                <Grid item className={classes.collapseColumn}>
                  <ListItemIcon
                    classes={{ root: classes.collapseIconOverride }}
                    onClick={() => setIsContinuedEventsCollapsed(prevState => !prevState)}
                  >
                    {!isContinuedEventsCollapsed ? <DownArrowIcon /> : <RightArrowIcon />}
                  </ListItemIcon>
                  {!isContinuedEventsCollapsed && <Box className={classes.verticalLine} />}
                </Grid>
                <Grid item className={classes.contentColumn}>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.headerContainer}
                    onClick={() => setIsContinuedEventsCollapsed(prevState => !prevState)}
                  >
                    <Typography
                      variantMapping={{ body1: 'span' }}
                      className={classes.contentHeader}
                    >
                      {continuedEvents.length > 0 ? `${continuedEvents.length} ` : ''}
                      Continued {continuedEvents.length === 1 ? 'Event' : 'Events'}
                    </Typography>
                    <Box className={classes.horizontalLine} />
                  </Grid>
                  <Collapse
                    in={!isContinuedEventsCollapsed}
                    classes={{
                      root: classes.containerOverride,
                      wrapperInner: classes.wrapperInnerOverride
                    }}
                  >
                    {continuedEvents?.length === 0 && (
                      <Grid container justifyContent="center">
                        <Box p={16}>
                          {detectedLoading || linktokenLoading ? (
                            <AlbLoading />
                          ) : (
                            <Typography>There are no continued events</Typography>
                          )}
                        </Box>
                      </Grid>
                    )}
                    {continuedEvents?.map(continuedEvent => (
                      <DetectedEventTableRow
                        key={continuedEvent.id}
                        dateRange={{
                          range: {
                            startDate: moment
                              .utc(start)
                              .clone()
                              .subtract(3, 'd'),
                            endDate: moment
                              .utc(end)
                              .clone()
                              .add(3, 'd')
                              .endOf('day')
                          }
                        }}
                        selectedDate={moment.utc(start)}
                        {...continuedEvent}
                        onClick={
                          continuedEvent.onClick
                            ? () =>
                                openDashboardDrawer(
                                  continuedEvent.event,
                                  drawerContext,
                                  socialPostDrawerContext
                                )
                            : null
                        }
                        graph
                        ref={continuedEvent.id === lastEventRowId ? bottomBoundaryRef : null}
                      />
                    ))}
                    {lazyLoading && (
                      <Grid container justifyContent="center">
                        <Box p={16}>
                          <AlbLoading />
                        </Box>
                      </Grid>
                    )}
                  </Collapse>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

DetectedEventsTable.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  typeFilter: PropTypes.arrayOf(PropTypes.string)
};

DetectedEventsTable.defaultProps = {
  typeFilter: null
};

export default DetectedEventsTable;
