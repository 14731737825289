import React from 'react';
import PropTypes from 'prop-types';
import {
  InstagramLogo,
  InstagramStoryLogo,
  InstagramReelLogo,
  FacebookLogo,
  TwitterLogo,
  YouTubeLogo,
  LinkedinLogo,
  GoogleLogo,
  GoogleAnalyticsLogo,
  GoogleAnalyticsWWWLogo,
  GoogleAnalyticsViewLogo,
  CorrelationLogo,
  TV_ICON,
  RADIO_ICON,
  ARTICLE_ICON,
  PODCAST_ICON,
  TV_AND_RADIO_ICON,
  AdobeAnalyticsLogo,
  AppStoreConnectLogo,
  GooglePlayConsoleLogo,
  NasdaqLogo,
  ShortlinkLogo,
  AppleLogo,
  AndroidLogo,
  SalesforceLogo,
  TikTokLogo,
  StarbucksLogo,
  StarbucksPlacerLogo,
  AmplifonLogo,
  RepublicLogo,
  InstacartLogo
} from 'util/assets';
import SIMPLE_SOCIAL_TYPE_ENUM from 'util/getSimpleSocialTypeEnum';

/**
 * @method
 * @summary This component renders the appropriate social platform logo depending on the link token type
 * @name SocialLogo
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const SocialLogo = props => {
  const { width, height, type, useMargin } = props;

  let imgSrc;
  let imgAlt;
  let imgWidth;
  let imgHeight;

  switch (type) {
    case SIMPLE_SOCIAL_TYPE_ENUM.FACEBOOK:
      imgSrc = FacebookLogo;
      imgAlt = 'Facebook Logo';
      imgWidth = 28;
      imgHeight = 28;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.INSTAGRAM:
      imgSrc = InstagramLogo;
      imgAlt = 'Instagram Logo';
      imgWidth = 28;
      imgHeight = 28;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.INSTAGRAM_STORY:
      imgSrc = InstagramStoryLogo;
      imgAlt = 'Instagram Story Logo';
      imgWidth = 28;
      imgHeight = 28;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.INSTAGRAM_REEL:
      imgSrc = InstagramReelLogo;
      imgAlt = 'Instagram Reel Logo';
      imgWidth = 28;
      imgHeight = 28;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.TWITTER:
      imgSrc = TwitterLogo;
      imgAlt = 'Twitter Logo';
      imgWidth = 29;
      imgHeight = 24;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.LINKEDIN:
      imgSrc = LinkedinLogo;
      imgAlt = 'LinkedIn Logo';
      imgWidth = 28;
      imgHeight = 28;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.YOUTUBE:
      imgSrc = YouTubeLogo;
      imgAlt = 'YouTube Logo';
      imgWidth = 37;
      imgHeight = 26;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE:
      imgSrc = GoogleLogo;
      imgAlt = 'Google Logo';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE_ANALYTICS:
      imgSrc = GoogleAnalyticsLogo;
      imgAlt = 'Google Analytics Logo';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE_WEB:
      imgSrc = GoogleAnalyticsWWWLogo;
      imgAlt = 'Google Analytics Property Logo';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE_VIEW:
      imgSrc = GoogleAnalyticsViewLogo;
      imgAlt = 'Google Analytics View Logo';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.CORRELATION:
      imgSrc = CorrelationLogo;
      imgAlt = 'Correlation Logo';
      imgWidth = 40;
      imgHeight = 45;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.SOCIAL_ANIMAL:
      imgSrc = ARTICLE_ICON;
      imgAlt = 'Article Logo';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.TV:
      imgSrc = TV_ICON;
      imgAlt = 'TV Logo';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.RADIO:
      imgSrc = RADIO_ICON;
      imgAlt = 'Radio Logo';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.PODCAST:
      imgSrc = PODCAST_ICON;
      imgAlt = 'Podcast Logo';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.BROADCAST:
      imgSrc = TV_AND_RADIO_ICON;
      imgAlt = 'Broadcast Logo';
      imgWidth = 60;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.ADOBE_ANALYTICS:
      imgSrc = AdobeAnalyticsLogo;
      imgAlt = 'Adobe Analytics';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.APPLE_APP_STORE_CONNECT:
      imgSrc = AppStoreConnectLogo;
      imgAlt = 'Apple App Store Connect';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE_PLAY_CONSOLE:
      imgSrc = GooglePlayConsoleLogo;
      imgAlt = 'Google Play Console';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.NASDAQ:
      imgSrc = NasdaqLogo;
      imgAlt = 'Nasdaq';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.SHORTLINK:
      imgSrc = ShortlinkLogo;
      imgAlt = 'Shortlink';
      imgWidth = 35;
      imgHeight = 12;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.APP_STORE_CONNECT_APP:
      imgSrc = AppleLogo;
      imgAlt = 'Apple';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE_PLAY_CONSOLE_APP:
      imgSrc = AndroidLogo;
      imgAlt = 'Android';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.SALESFORCE:
      imgSrc = SalesforceLogo;
      imgAlt = 'Salesforce';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.TIKTOK:
      imgSrc = TikTokLogo;
      imgAlt = 'TikTok';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.STARBUCKS:
      imgSrc = StarbucksLogo;
      imgAlt = 'Starbucks';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.STARBUCKS_PLACER:
      imgSrc = StarbucksPlacerLogo;
      imgAlt = 'Placer';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.AMPLIFON:
      imgSrc = AmplifonLogo;
      imgAlt = 'Amplifon';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.REPUBLIC:
      imgSrc = RepublicLogo;
      imgAlt = 'Republic';
      imgWidth = 30;
      imgHeight = 30;
      break;
    case SIMPLE_SOCIAL_TYPE_ENUM.INSTACART:
      imgSrc = InstacartLogo;
      imgAlt = 'Instacart';
      imgWidth = 30;
      imgHeight = 30;
      break;
    default:
      break;
  }

  if (width) {
    imgWidth = width;
  }

  if (height) {
    imgHeight = height;
  }

  if (imgSrc) {
    return (
      <img
        src={imgSrc}
        alt={imgAlt}
        width={imgWidth}
        height={imgHeight}
        style={{ ...(useMargin && { margin: imgWidth / 2 }) }}
      />
    );
  }
  // If there's no logo match above, default to a white circle as a placeholder (should never happen, though)
  return (
    <div
      style={{
        width: 28,
        height: 28,
        borderRadius: '50%',
        backgroundColor: '#ffffff',
        display: 'inline-block'
      }}
    />
  );
};

SocialLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  type: PropTypes.string.isRequired,
  useMargin: PropTypes.bool
};

SocialLogo.defaultProps = {
  width: null,
  height: null,
  useMargin: null
};

export default SocialLogo;
